import { WalletHistoryQuery } from './__generated__/sdk';
import { WalletTransactionFilterType } from './__generated__/types';
import { getCoreClient } from './client';

export interface GetWalletHistoryProps {
  page: number;
  perPage: number;
  sortField: string;
  sortOrder: string;
  filter: WalletTransactionFilterType;
}

export async function getWalletHistory({
  page,
  perPage,
  sortField,
  sortOrder,
  filter,
}: GetWalletHistoryProps): Promise<WalletHistoryQuery> {
  const client = await getCoreClient();
  return client.WalletHistory({ page, perPage, sortField, sortOrder, filter });
}
