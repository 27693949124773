import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { getPortfolioStatistics, CoreSDK, QueryKeys } from '@investown/fe/api-sdk';

export function usePortfolioStatistics(
  queryOptions?: UseQueryOptions<CoreSDK.PortfolioStatisticsQuery, Error>
): UseQueryResult<CoreSDK.PortfolioStatisticsQuery, Error> {
  return useQuery<CoreSDK.PortfolioStatisticsQuery, Error>([QueryKeys.PortfolioStatistics], getPortfolioStatistics, {
    ...queryOptions,
  });
}
