import { ClientError } from 'graphql-request';
import { GraphQLError } from 'graphql';
import { Amplify } from '@aws-amplify/core';

import { AmplifyResponseApiError, AmplifyResponseGeneralError, ApiErrorBody } from './model';
import { ApiError } from './ApiError';
import { clientErrorCodes } from './constants';
import { SdkFunctionWrapper } from './core/__generated__/sdk';

Amplify.Logger.LOG_LEVEL = 'ERROR';

function isApiErrorBody(body: ApiErrorBody | unknown): body is ApiErrorBody {
  return (body as ApiErrorBody).statusCode !== undefined && (body as ApiErrorBody).error !== undefined;
}

export function isAmplifyResponseGeneralError(
  error: AmplifyResponseGeneralError | Error
): error is AmplifyResponseGeneralError {
  return (error as AmplifyResponseGeneralError).response !== undefined;
}

export function isAmplifyResponseApiError(
  error: AmplifyResponseGeneralError | AmplifyResponseApiError
): error is AmplifyResponseApiError {
  const body = error.response.data;
  if (!body) {
    return false;
  }
  return isApiErrorBody(body);
}

export function createApiErrorFromAmplifyError(error: AmplifyResponseApiError): ApiError {
  return new ApiError(
    error.response.data.message ? error.response.data.message : error.response.data.error,
    error.response.status,
    error.response.data.errorCode
  );
}

export function createApiErrorFromGraphQLError(error: ClientError): ApiError {
  const responseErrors = error.response.errors as unknown as GraphQLError[] | undefined;
  const firstError = responseErrors && responseErrors[0];
  if (!firstError) {
    throw new Error('Response does not contain any error to parse');
  }

  const code = firstError.extensions ? firstError.extensions.code : undefined;
  return new ApiError(firstError.message, undefined, code);
}

/**
 * Api error with status 400-499 or with error code caused by the client.
 */
export function isClientError(err: Error): boolean {
  if (err instanceof ApiError && err.status && err.status >= 400 && err.status < 500) {
    return true;
  }
  if (err instanceof ApiError && err.code && clientErrorCodes.includes(err.code)) {
    return true;
  }
  return false;
}

/**
 * Wraps generated GraphQL SDK with error handling middleware
 * @see https://graphql-code-generator.com/docs/plugins/typescript-graphql-request#simple-request-middleware
 */
export const sdkFunctionWrapper: SdkFunctionWrapper = async <T>(action: () => Promise<T>): Promise<T> => {
  try {
    return await action();
  } catch (err) {
    // Transform GraphQL errors to ApiError
    if (err instanceof ClientError && err.response.errors && err.response.errors.length > 0) {
      throw createApiErrorFromGraphQLError(err);
    }
    throw err;
  }
};
