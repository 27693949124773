import { PageableInvestmentRoundsSecondaryMarketInfosQuery } from './__generated__/sdk';
import { PageRequest, SortRequest } from './model';
import { getCoreClient } from './client';

export async function getPageOfInvestmentRoundsSecondaryMarket({
  pageRequest,
  sortRequest,
}: {
  pageRequest: PageRequest;
  sortRequest?: SortRequest;
}): Promise<PageableInvestmentRoundsSecondaryMarketInfosQuery> {
  const coreClient = await getCoreClient();
  const { page, perPage } = pageRequest;
  return coreClient.PageableInvestmentRoundsSecondaryMarketInfos({
    page,
    perPage,
    sortField: sortRequest?.sortField,
    sortOrder: sortRequest?.sortOrder,
  });
}
