import { RelatedPropertiesQuery, RelatedPropertiesQueryVariables } from './__generated__/sdk';
import { getCoreClient } from './client';

export async function getPartnerRelatedProjects({
  slug,
  borrowerId,
  page,
  perPage,
}: RelatedPropertiesQueryVariables): Promise<RelatedPropertiesQuery> {
  const coreClient = await getCoreClient();
  return coreClient.RelatedProperties({
    slug,
    borrowerId,
    page,
    perPage,
  });
}
