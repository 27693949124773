import { PageablePortfolioSizeMonthlyHistoryQuery } from './__generated__/sdk';
import { getCoreClient } from './client';
import { PageRequest } from './model';

export async function getPageablePortfolioSizeMonthlyHistory({
  pageRequest,
}: {
  pageRequest: PageRequest;
}): Promise<PageablePortfolioSizeMonthlyHistoryQuery> {
  const coreClient = await getCoreClient();
  const { page, perPage } = pageRequest;
  return coreClient.PageablePortfolioSizeMonthlyHistory({
    page,
    perPage,
  });
}
