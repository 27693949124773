import { createGraphQLClient } from '../api';
import { sdkFunctionWrapper } from '../utils';

import { getSdk } from './__generated__/sdk';

let coreGQLEndpoint: string;

export function initCoreClient(endpoint: string): void {
  coreGQLEndpoint = endpoint;
}

/**
 * Returns generated SDK for core microservice.
 * For instructions how to regenerate the SDK see README in the project root.
 * More info: https://graphql-code-generator.com/docs/plugins/typescript-graphql-request
 */
export async function getCoreClient(): Promise<ReturnType<typeof getSdk>> {
  if (!coreGQLEndpoint) {
    throw new Error('Missing coreGQLEndpoint variable');
  }
  // TODO: Save created GraphQLClient into caches but keep in mind that we need to reset these cache externally.
  return getSdk(await createGraphQLClient({ endpoint: coreGQLEndpoint!, authenticated: true }), sdkFunctionWrapper);
}
