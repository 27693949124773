import { InvestedPropertiesFilterType, InvestedPropertiesQuery } from './__generated__/sdk';
import { getCoreClient } from './client';

export interface InvestedPropertiesQueryProps {
  page: number;
  perPage: number;
  sortField: string;
  sortOrder: string;
  filter?: InvestedPropertiesFilterType | undefined;
}

export async function getInvestedProperties({
  page,
  perPage,
  sortField,
  sortOrder,
  filter,
}: InvestedPropertiesQueryProps): Promise<InvestedPropertiesQuery> {
  const coreClient = await getCoreClient();
  return coreClient.InvestedProperties({
    page,
    perPage,
    sortField,
    sortOrder,
    filter,
  });
}
