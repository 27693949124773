import { Store } from 'redux';
import CookiesProvider from 'universal-cookie';

import * as api from '@investown/fe/api-sdk';
import { LocalazyCDN } from '@investown/fe/common-utils';

import { initFirebase, loadRemoteConfigValues } from './util/firebase';

import * as auth from 'auth/auth';
import { RootState } from 'appRedux/store/rootReducer';
import intercomInitialize from 'util/intercomInitialize';
import { getAppLanguageLocalVariable } from 'lngProvider';
import { initSentry, setOnErrorCallbacks } from 'util/sentry';
import { history } from 'appRedux/store';

export async function init(options: { store: Store<RootState> }): Promise<void> {
  initSentry({ history });

  intercomInitialize(); // needs to be before auth init
  cookiesCleanup();

  await LocalazyCDN.init({
    metaFileURL: process.env.REACT_APP_LOCALAZY_META_FILE_URL,
    fileId: process.env.REACT_APP_LOCALAZY_FILE_ID,
    enable: process.env.REACT_APP_LOCALAZY_ENABLE === 'enable',
  });

  await auth.init({ store: options.store });

  initFirebase();
  await loadRemoteConfigValues();
  setOnErrorCallbacks(api.queryClient);

  const apiUrl = process.env.REACT_APP_MONOREPO_API_URL;
  if (!apiUrl) {
    throw new Error('Missing REACT_APP_MONOREPO_API_URL env variable');
  }

  const acceptLanguage = getAppLanguageLocalVariable();
  api.init({ acceptLanguage, apiUrl });

  const ordersGQLEndpoint = process.env.REACT_APP_GQL_ORDERS;
  if (!ordersGQLEndpoint) {
    throw new Error('Missing REACT_APP_GQL_ORDERS env variable');
  }
  api.initOrdersClient(ordersGQLEndpoint);

  const coreGQLEndpoint = process.env.REACT_APP_GQL_CORE;
  if (!coreGQLEndpoint) {
    throw new Error('Missing REACT_APP_GQL_CORE env variable');
  }
  api.initCoreClient(coreGQLEndpoint);

  const notificationsGQLEndpoint = process.env.REACT_APP_GQL_NOTIFICATIONS;
  if (!notificationsGQLEndpoint) {
    throw new Error('Missing REACT_APP_GQL_NOTIFICATIONS env variable');
  }
  api.initNotificationsClient(notificationsGQLEndpoint);

  const affiliateGQLEndpoint = process.env.REACT_APP_GQL_AFFILIATE;
  if (!affiliateGQLEndpoint) {
    throw new Error('Missing REACT_APP_GQL_AFFILIATE env variable');
  }
  api.initAffiliateClient(affiliateGQLEndpoint);
}

export const cookiesCleanup = (): void => {
  const localStorageKeyName = 'universalCookiesCleanupV2Done';
  if (!localStorage.getItem(localStorageKeyName)) {
    const Cookies = new CookiesProvider();
    let shouldReload = false;
    const allCookiesKeys = Object.keys(Cookies.getAll());

    allCookiesKeys.forEach((cookie: string) => {
      if (cookie.includes('Cognito')) {
        // Prod
        Cookies.remove(cookie, { domain: '.investown.cz' });
        Cookies.remove(cookie, { domain: 'my.investown.cz' });
        Cookies.remove(cookie, { domain: '.my.investown.cz' });

        // Dev
        Cookies.remove(cookie, { domain: '.investown.net' });
        Cookies.remove(cookie, { domain: 'dev.investown.net' });
        Cookies.remove(cookie, { domain: '.dev.investown.cz' });

        shouldReload = true;
      }
    });

    localStorage.setItem(localStorageKeyName, '1');
    if (shouldReload) {
      document.location.reload(); // Cookies are set to be expired but to remove them you need to refresh the page
    }
  }
};

export function getConfigCatKey(): string {
  const configCatSdkKey = process.env.REACT_APP_CONFIG_CAT_SDK_KEY_V2 || process.env.REACT_APP_CONFIG_CAT_SDK_KEY;
  if (!configCatSdkKey || configCatSdkKey === '') {
    throw new Error('Missing REACT_APP_CONFIG_CAT_SDK_KEY env variable');
  }

  return configCatSdkKey;
}

export function getRecaptchaKey(): string {
  const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;
  if (!recaptchaKey) {
    throw new Error('Missing REACT_APP_RECAPTCHA_KEY env variable');
  }

  return recaptchaKey;
}
