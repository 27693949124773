import { MonthlyProfitQuery } from './__generated__/sdk';
import { getCoreClient } from './client';

export interface GetMonthlyProfitProps {
  page?: number;
  perPage: number;
}

export async function getMonthlyProfit({ page = 0, perPage }: GetMonthlyProfitProps): Promise<MonthlyProfitQuery> {
  const coreClient = await getCoreClient();
  return coreClient.MonthlyProfit({ page, perPage });
}
