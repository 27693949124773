import { SecondaryMarketBidsQuery } from './__generated__/sdk';
import { PageRequest } from './model';
import { getCoreClient } from './client';

export async function getSecondaryMarketBids({
  pageRequest,
}: {
  pageRequest: PageRequest;
}): Promise<SecondaryMarketBidsQuery> {
  const coreClient = await getCoreClient();
  const { page, perPage } = pageRequest;
  return coreClient.SecondaryMarketBids({
    page,
    perPage,
  });
}
