import { InfiniteData, UseInfiniteQueryResult } from 'react-query';
import capitalize from 'lodash/capitalize';
import { format } from 'date-fns';

import * as CoreSDK from '../core/__generated__/sdk';
import { getDateFnsLocale } from '../locales';

import { useInfiniteTransactions } from './useInfiniteTransactions';

export type WalletHistoryItem = Pick<
  CoreSDK.WalletTransactionObjectType,
  'createdAt' | 'type' | 'amount' | 'currency' | 'projectInfo' | 'isAutoinvest'
>;

type SectionInfo = { id: string; month: number; year: number };
export type Sections = { [id: string]: SectionInfo & { data: WalletHistoryItem[] } };
interface WalletDataWithSections extends InfiniteData<CoreSDK.WalletHistoryQuery> {
  sections?: Sections;
  filter?: CoreSDK.WalletTransactionFilterType;
}

export function useInfiniteTransactionsWithSections(
  { perPage = 20, filter }: { perPage?: number; filter?: CoreSDK.WalletTransactionFilterType },
  queryOptions?: Parameters<typeof useInfiniteTransactions>[1]
): Omit<UseInfiniteQueryResult<CoreSDK.WalletHistoryQuery, Error>, 'data'> & {
  data: WalletDataWithSections | undefined;
} {
  return useInfiniteTransactions(
    { perPage, filter },
    {
      // Important: don't use inline function here, createSection needs to be memoized
      // https://tkdodo.eu/blog/react-query-data-transformations#3-using-the-select-option
      select: createSections,
      ...queryOptions,
    }
  );
}

function createSections(data: InfiniteData<CoreSDK.WalletHistoryQuery>): WalletDataWithSections {
  const sections = {};

  data?.pages.forEach((page) => {
    page.items.forEach((transaction) => {
      addTransactionToSection(sections, transaction);
    });
  });
  // we are updating the sections object directly be cause it's part of the useQuery data object
  // And when the query is invalidated, the sections will also be deleted. This will prevent
  // the sections from having duplicated data.
  return Object.assign(data, { sections });
}

function addTransactionToSection(
  sections: { [title: string]: SectionInfo & { data: WalletHistoryItem[] } },
  transaction: WalletHistoryItem
): void {
  const sectionInfo = getTransactionSectionInfo(transaction, 'cs');
  if (Object.hasOwn(sections, sectionInfo.id)) {
    sections[sectionInfo.id].data.push(transaction);
    return;
  }

  // same reason as the reason above
  // eslint-disable-next-line no-param-reassign
  sections[sectionInfo.id] = {
    ...sectionInfo,
    data: [transaction],
  };
}

function getTransactionSectionInfo(
  { createdAt }: WalletHistoryItem,
  locale: string
): { id: string; month: number; year: number } {
  const transactionCreatedAt = new Date(createdAt);
  return {
    id: `${transactionCreatedAt.getFullYear()}-${transactionCreatedAt.getMonth()}`,
    month: transactionCreatedAt.getMonth(),
    year: transactionCreatedAt.getFullYear(),
  };
}

export function getSectionTitle(section: Sections[keyof Sections], locale: string): string {
  const date = new Date(section.year, section.month);
  const formattedDate = format(date, 'LLLL yyyy', { locale: getDateFnsLocale(locale) });
  return capitalize(formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1));
}
