import { UserInvestmentStatsQuery } from './__generated__/sdk';
import { getCoreClient } from './client';

export async function getUserInvestmentStats({
  currency = 'CZK',
}: {
  currency: string;
}): Promise<UserInvestmentStatsQuery> {
  const coreClient = await getCoreClient();
  return coreClient.UserInvestmentStats({
    input: {
      currency,
    },
  });
}
