import { TotalUserLevelBonusYieldQuery } from './__generated__/sdk';
import { getCoreClient } from './client';

export async function getTotalUserLevelBonusYield({
  propertyInvestmentRoundId,
  onlyCurrentMonth = false,
}: {
  propertyInvestmentRoundId?: string;
  onlyCurrentMonth?: boolean;
}): Promise<TotalUserLevelBonusYieldQuery> {
  const coreClient = await getCoreClient();
  return coreClient.TotalUserLevelBonusYield({
    input: {
      propertyInvestmentRoundId,
      onlyCurrentMonth,
    },
  });
}
