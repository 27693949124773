import { AverageYieldMonthlyHistoryQuery } from './__generated__/sdk';
import { getCoreClient } from './client';
import { PageRequest } from './model';

export async function getPageOfAverageYieldMonthlyHistory({
  page,
  perPage,
}: PageRequest): Promise<AverageYieldMonthlyHistoryQuery> {
  const coreClient = await getCoreClient();
  return coreClient.AverageYieldMonthlyHistory({ page, perPage });
}
